<template>
    <div v-if="isList" class="content-wrapper">
        <bo-page-title></bo-page-title>
        <div class="content pt-0">
            <div class="card">
                <div class="bg-white card-header">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-lg-5">
                            <div class="form-row">
                                <div class="col-lg-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                <div class="col-lg-8">
                                    <div class="form-group mb-0 d-flex">
                                        <date-range-picker
                                            ref="picker"
                                            :locale-data="datePickerConfig.locale"
                                            :autoApply="datePickerConfig.autoApply"
                                            v-model="dateRange"
                                            :opens="'right'"
                                            :ranges="datePickerConfig.ranges"
                                            @update="updateValues"
                                        >
                                            <template v-slot:input="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                        </date-range-picker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-row">
                                <div class="col-md-auto">
                                    <div class="form-group mb-0">
                                        <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4" v-if="isshowTable('mpo_name')">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="table-responsive sticky-table">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                <th v-if="isshowTable('mpo_name')">Nama Poli</th>
                                <th v-if="isshowTable('ap_code')">No. RM</th>
                                <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                <th v-if="isshowTable('ap_usia')">Usia</th>
                                <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                                <th v-if="isshowTable('ar_penunjang')">Penunjang</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody v-if="!loadingTable" >
                            <template v-for="(v,k) in dataReg" >
                                <tr :key="k">
                                    <td>{{k+1}}</td>

                                    <td v-if="isshowTable('ap_fullname')">
                                        <span>{{uppercaseWord(v.ap_fullname)||'-'}}</span>
                                        <p>{{v.ap_nik||"-"}}</p>
                                    </td>
                                    <td v-if="isshowTable('ar_reg_date')">
                                    {{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                    <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                    <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                    <td v-if="isshowTable('ap_usia')" v-b-tooltip.hover>
                                    {{v.ap_usia_with_ket||"-"}}
                                    <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                    </td>
                                    <td v-if="isshowTable('cg_label')">{{v.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</td>
                                    <td v-if="isshowTable('ar_penunjang')">
                                      <span class="badge badge-success mb-1" v-if="v.ar_is_ppa_lab == 'Y'" >Laboratorium</span>
                                      <span class="badge badge-primary mb-1" v-if="v.ar_is_ppa_radiologi == 'Y'">Radiologi</span>
                                      <span class="badge badge-success mb-1" v-if="v.ar_is_ppa_fisio == 'Y'">Fisioterapi</span>
                                      <span class="badge badge-primary mb-1" v-if="v.ar_is_ppa_dietisien == 'Y'">Dietisien</span>

                                      <span v-if="v.ar_is_ppa_lab != 'Y' && v.ar_is_ppa_radiologi != 'Y' && v.ar_is_ppa_fisio != 'Y' && v.ar_is_ppa_dietisien != 'Y'"> - </span>
                                    </td>
                                    <td>
                                      <a href="javascript:;" v-b-tooltip.hover title="Lihat Tindakan Penunjang Pasien" @click="viewTindakan(v)" class="btn btn-icon alpha-info border-info text-info-800 rounded-round"><i class="icon-file-eye2"></i></a>

                                      <a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN'"
										@click="viewDetail(v)" href="javascript:;" data-toggle="modal"
										data-target="#Fasttrack" data-popup="tooltip" v-b-tooltip.hover title="Lihat Detail Pasien"
										class="btn btn-icon alpha-success border-success text-success-800 rounded-round mt-1"
										data-original-title="Lihat Detail Pasien"><i class="icon-eye"></i></a>

                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="loadingTable">     
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                        <tbody v-if="loadingInvinite">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                        </tbody>
                        <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                            <tr>
                                <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                    Data Tidak Ditemukan
                                </th>
                            </tr>                
                        </tbody>                
                    </table>
                    <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal 
          v-model="modalDetail"
          :title="'Detail Tindakan Penunjang'"
          size="xl"
          ok-title="Tutup"
          ok-only
          @ok="modalDetail=false"
        >
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <tr class="table-light">
                    <th class="text-uppercase">Jenis Penunjang</th>
                    <th class="text-uppercase">Nama Tindakan</th>
                    <th class="text-uppercase">Cara Bayar</th>
                    <th class="text-uppercase">Referensi</th>
                    <th class="text-uppercase">Tanggal Pengkajian</th>
                    <th class="text-uppercase">Jadwal Tindakan</th>
                    <th class="text-uppercase">Status</th>
                    <th class="text-uppercase">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in (modalData||[])" :key="k">
                    <td>
                      <span class="badge badge-success mb-1 d-block" v-if="v.type == 'LAB'" >Laboratorium</span>
                      <span class="badge badge-info mb-1 d-block" v-if="v.type == 'RADIO'" >Radiologi</span>
                    </td>
                    <td>
                      {{v.tindakan}}
                    </td>
                    <td>
                      {{rowEdit.mcp_name}}
                    </td>
                    <td>
                      <span class="font-weight-bold d-block">
                        {{rowEdit.ar_reg_code}} - {{rowEdit.mpo_name}}
                      </span>
                      <span>{{rowEdit.bu_full_name}}</span>
                    </td>
                    <td>{{rowEdit.ar_reg_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                    <td>{{v.date | moment("DD MMMM YYYY")}}</td>
                    <td>
                        <template v-if="v.isBatal">
                            <span class="badge badge-danger mb-1 d-block">
                                Dibatalkan
                            </span>
                            <span>Dibatalkan Oleh : {{v.batal_by}} Pada {{v.batal_date | moment("DD MMMM YYYY, HH:mm")}}, Alasan Batal : {{v.reason}}</span>
                        </template>
                        <template v-else>
                            <span class="badge badge-success mb-1 d-block" v-if="v.status == 'Y'" >Sudah Dilaksanakan</span>
                            <span class="badge badge-warning mb-1 d-block" v-else >Belum Dilaksanakan</span>
                        </template>
                    </td>
                    <td>
                      <template v-if="v.status != 'Y' && canBatal(v.type)">
                      <a href="javascript:;" v-b-tooltip.hover title="Batalkan Tindakan Penunjang Pasien" @click="batalkanTindakanPenunjangV2(v,v.type,k)" class="btn btn-sm btn-icon alpha-danger border-danger text-danger-800 rounded-round"><i class="icon-notification2"></i></a>
                      </template>
                      <span v-else> - </span>
                    </td>
                  </tr>
                  <tr v-if="!(modalData||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>

        <validation-observer
            ref="VFormBatal"
        >
        <b-modal body-class="p-0" id="formMedicalRecord" size="md" hide-header hide-footer v-model="tindakanPenunjangModal">
            <div>
                <div class="modal-body">
                    <div class="text-center">
                    <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                        <i class="icon-check"></i>
                    </div>
                    <h6 class="mb-3">
                        {{textModal}}
                    </h6>
                    <div class="mb-3">
                        <b-form-textarea class="form-control" placeholder="Alasan Batal" v-model="ketBatal" />
                        <VValidate 
                            name="Alasan Batal" 
                            v-model="ketBatal" 
                            :rules="{required: 1, min:3}"
                        /> 
                    </div>
                    <div>
                        <a href="javascript:;" @click="batalkanTindakanPenunjang(modalConfirmData.selectedItem,modalConfirmData.type,modalConfirmData.idx)" data-dismiss="modal" class="mr-1 btn btn-success btn-labeled btn-labeled-left">
                        <b><i class="icon-check"></i></b>
                        Ya, Lanjutkan
                        </a>
                        <a href="javascript:;" @click="tindakanPenunjangModal = false" data-dismiss="modal" class="mr-1 btn btn-outline-secondary">
                        Batal
                        </a>
                    </div>
                    </div>
                </div>
            </div>
        </b-modal>
        </validation-observer>
        
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'

export default {
    extends: GlobalVue,
    data() {
        return {
            // LIST COMP ==============
            mPoli: [],
            dataReg: [],
            mInsiden: [],
            rowEdit: {},
            rowReg: {},
            loadingInvinite: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            selectedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },            
            modalDetail: false,
            rowDetail: [],
            activeTab: 0,
            activeTabInsiden: 0,
            openExport: false,
            modalData: [],
            tindakanPenunjangModal: false,
            textModal: '',
            ketBatal: null,
            modalConfirmData: {}


            // LIST COMP ================
        }
    },
    computed:{
        passToSub(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                mInsiden: this.mInsiden
            })
        }
    },
  	components:{
        DateRangePicker
    },
    methods: {
      
        canBatal(item){
          let userLevel = this.user.levelId
          // this.user.levelId
          let res = false
          if(userLevel == this.uRadiologi && item == 'RADIO'){
            res = true
          }else if(userLevel == this.uLab && item == 'LAB'){
            res = true
          }else if(userLevel == 1){
            res = true
          }
          return res
        },
        changeTab(e){
            this.activeTab = e
        },
        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query

            let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")

            if(this.isList){
                this.doConvertDate()
                if((+this.$route.query.page||1) == 1){
                    this.loadingTable = true
                }
            }else{
                this.loadingOverlay = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage+slugs, 
                {
                    params: Object.assign({
                        selectedFilter: this.isList ? this.selectedFilter.join(",") : '',
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data    
                if(this.isList){
                    this.loadingTable = false
                    this.loadingInvinite = false
                    this.totalData = resp.dataReg.total

                    if((+this.$route.query.page||1) !== 1){
                        let data = resp.dataReg.data
                        for(let i = 0; i < (data||[]).length; i++){
                            this.dataReg.push(data[i])
                        }
                    }else{
                        this.dataReg = resp.dataReg.data
                    }   
                }else{
                    this.loadingOverlay = false
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                    
                    if(!this.isFound){
                        this.$router.push({name : this.$route.name}).catch(()=>{})
                    }
                }
            })
            
            Gen.apiRest(
                "/get/"+'Master'+
                '/poli'
            ).then(res=>{
                let resp = res.data
                this.mPoli = resp.mPoli
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == this.modulePage && this.isList){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
        },

        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableHead = responsiveTable.querySelector('thead')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            const tableOffsetTop = responsiveTable.offsetTop + responsiveTable.offsetParent.offsetTop
            responsiveTableHead.style.position = 'relative'
            responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            window.addEventListener('scroll', e => {
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                if (document.documentElement.scrollTop > tableOffsetTop) {
                    responsiveTableHead.style.top = (document.documentElement.scrollTop - tableOffsetTop)+64+'px'
                }else{
                    responsiveTableHead.style.top = '0px'
                }
            })
            Array.from(responsiveTableScrollActions).forEach(action => {
                action.addEventListener('click', e => {
                    const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                     
                    responsiveTable.scroll({
                        left: scrollAmount,
                        behavior: 'smooth'
                    })
                })
            })
        },

        viewTindakan(row){
          this.modalDetail = true,
          this.modalData = JSON.parse(row.ar_penunjang_data)
          this.rowEdit = row
        },
        viewDetail(row){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: row.ap_id }, query: {regId: row.ar_id, byPassLevel: 5} }).catch(()=>{})
            // view pov dokter 
        },
        batalkanTindakanPenunjangV2(selectedItem,type,idx){
            let typePasien = ''
            if(type == 'RADIO'){
                typePasien = "Radiologi"
            }else{
                typePasien = "Laboratorium"
            }
            this.tindakanPenunjangModal = true
            this.textModal = `Apakah Anda yakin untuk membatalkan jadwal ${typePasien} pasien ${this.rowEdit.ap_fullname} ?`  
            this.modalConfirmData.selectedItem = selectedItem
            this.modalConfirmData.type = type
            this.modalConfirmData.idx = idx
        },
        batalkanTindakanPenunjang(selectedItem,type,idx){
            this.$refs['VFormBatal'].validate().then(success=>{
                if(success){
                    // sengaja di bikin array biar bisa langsung klo ada req multiple
                    let data = {
                        selectedItem : [selectedItem],
                        dataAll : this.modalData,
                        idx: [idx],
                        ar_id: this.rowEdit.ar_id,
                        type: "batalkan-tindakan",
                        reason: this.ketBatal
                    }
                    Gen.apiRest('/do/' + this.modulePage, {
                        data: data
                    }, 'POST').then(() => {
                        this.$swal({
                            title: `Jadwal Berhasil Dibatalkan`,
                            icon: 'success',
                        })

                        this.tindakanPenunjangModal = false
                        this.loadingOverlay = false
                        this.modalDetail = false
                        this.modalData = []
                        this.rowEdit = {}
                        this.apiGet(['list', 'resume'])
                    }).catch(err => {
                        this.loadingOverlay = false
                        if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                        }
                        this.doSetAlertForm(err)
                    })
                }
            })


        },
        
        doRefreshData(){
            this.apiGet()
            window.scrollTo(0,0)
        },
    },
    mounted() {
        if(this.isList){
            this.filter.page = 1
            if(this.$route.query.page != 1){
                let url = {
                    name:this.$route.name,
                    params: (this.$route.params||{}),
                    query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
                }
                this.$router.push(url).catch(()=>{})
            }else{
                this.apiGet()
            }
        }else{
            this.apiGet()
        }
        
        setTimeout(()=>{
            if(this.isList){
                this.setSlide()
            }
        },1000)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
    },  
    created () {
    },
    unmounted () {
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
    },
    watch:{
        $route(){
            this.apiGet()
            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>